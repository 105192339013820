<template>
  <v-main>
    <v-container>
      <explore-nitrate></explore-nitrate>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          'Explore the association between Nitrates in drinking water and your risk for cancer and disease.'
      }
    ],
    title: 'Water Nitrates And Your Health'
  },
  components: {
    ExploreNitrate: () => import('@/components/ExploreNitrate')
  }
}
</script>

<style></style>
